//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// eslint-disable-next-line import/no-named-as-default
import Swiper, { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'

Swiper.use([Navigation, Pagination])

// taken from fb: https://www.facebook.com/nordiskmusculupati/reviews/?ref=page_internal
// project is too small/simple to get data from fb api. taking the simple approach
export const reviews1 = [
  {
    id: 1,
    title: 'Alberte Marina Sørensen',
    body: 'Jeg har haft flere forløb hos Benjamin dels med migræne og dels med udfordringer med maven. Ved alle behandlinger har jeg følt mig i trygge hænder og taget godt imod. Benjamin har lyttet til de udfordringer jeg kom med og har fundet årsag til dem fremfor at behandle på symptomer. Han er god til at guide igennem undervejs i behandlingen, så man forstår hvorfor han gør de forskellige ting. Derudover er han en sød og snakkesalig person. Jeg kan derfor klart anbefale Benjamin og Nordisk Musculupati!',
    link: 'https://www.facebook.com/permalink.php?story_fbid=6493517357328702&id=100000115057649'
  },
  {
    id: 2,
    title: 'Anders Hofman',
    body: `Benjamin v. Nordisk Musculupati, er den eneste, der har kunnet afhjælpe mine skuldersmerter. Jeg har en skulder, der har været opereret to gange, og som har været igennem diverse genoptræningsfoeløb og alternative behandlingsforme uden effekt.
    Siden jeg startede hos Benjamin, har jeg fået fuld førlighed tilbage og smerterne aftager mere og mere for hver behandling. Jeg glæder mig altid til, at jeg skal til behandling.`,
    link: 'https://g.co/kgs/66iAHR'
  },
  {
    id: 3,
    title: 'Camilla Zindel',
    body: 'Har haft forfærdelige knæ smerter, kun efter 2 behandlinger ved Benjamin var de nærmest væk. Benjamin er yderst professionel og har rigtig mange kompetencer. Uanset hvilken skade eller smerte jeg havde kunne han hjælpe mig videre. Jeg vil til en hvert tid anbefale Benjamin fra Nordisk Musculupati.',
    link: 'https://www.facebook.com/camilla.zindel/posts/10158507184119858'
  }
]
export const reviews2 = [
  {
    id: 4,
    title: 'Ida Marie Hansen',
    body: `Jeg har været ved Benjamin et par gange til behandlinger og kommer også gerne igen.. Sidst havde jeg voldsomme smerter i min lænd og ned i benet, kunne hverken rejse mig, vende mig eller gå ordentligt.. Benjamin var god til at forklare mig om kroppen og hvad det kunne være og han lavede en grundig gennemgang..
    Efter nogle dybe tryk kunne jeg selv rejse mig og efterfølgende både vende og dreje mig som jeg ville.. Vil klart anbefale en behandling her 😊🙏🏽`,
    link: 'https://www.facebook.com/idamarie.hansen.73/posts/pfbid031TRzAucnNKG19yhG9W4uLWnZ6b84Pz8AXpYFrUSpB5mHwYhQVtx93p8ZdWC5wPEtl'
  },
  {
    id: 5,
    title: 'Natascha Due Juul Hulkvist',
    body: 'Kan helt klart anbefale Nordisk Musculupati! Jeg har prøvet flere forskellige andre steder, for at afhjælpe min migræne, men ingenting har rigtig hjulpet. Allerede efter 1. gang hos Benjamin var der væsentlig bedring, og jeg er færdig nu efter 3. gang. Han lindrer ikke kun symptomerne, men arbejder på selve årsagen, og jeg er så glad! Kan kun anbefale!',
    link: 'https://www.facebook.com/natascha.hulkvist/posts/10159518098442812'
  },
  {
    id: 6,
    title: 'Gitte Dyhr',
    body: 'Alletiders behandling. Man er i centrum under hele behandlingen,  og ikke samlebåndsoplevelsen ved det offentlige og gennem sundhedsforsikring. Benjamin er en behandler i verdensklasse.',
    link: 'https://g.co/kgs/ehz9EM'
  }
]
export const reviews3 = [
  {
    id: 7,
    title: 'Lisbeth Alma Dilling',
    body: `Da jeg søgte hjælp havde jeg konstant hovedpine efter en voldsom skade på mine ribben.
Og jeg fik stærk smertestillende 3 gange i døgnet.
Efter første behandling var der stor bedring, mine smerter blev halveret.
Og nu efter to behandlinger har jeg måske hovedpine 1 gang i ugen og tager kun smertestillende 3 til 4 gange om ugen.
Jeg kan klart anbefale Benjamin.`,
    link: 'https://www.facebook.com/lisbeth.dilling/posts/10157989838718472'
  },
  {
    id: 8,
    title: 'Helle Hjorth',
    body: `Jeg kom til Benjamin med en gammel skulderskade som jeg har haft i 17 år.
Jeg har prøvet diverse behandlinger, men intet har kunne få min smerte i skulderen til at forsvinde helt.
Efter kun 3 behandlinger, er jeg helt fri for smerte og har fuld bevægelighed i min skulder igen.
Oveni er Benjamin også rigtig behagelig`,
    link: 'https://www.facebook.com/helle.hjorth.946/posts/10225090508439225'
  },
  {
    id: 9,
    title: 'Samir Karadza',
    body: `Grundet tidligere fodboldskader, har jeg igennem længere tid gået rundt med smerter i benene, lænden og i hofteregionen.
Men efter et par behandlinger hos Nordisk Musculupati Benjamin, er der sket store fremskridt. Spændingerne i kroppen er væk, jeg føler min krop er mere afslappende og bevægelig end før.
I løbet af de sidste 14 dage, har jeg endda formået at spille 6 fodboldkampe i denne periode uden, at det har forvoldt mig problemer.
Benjamin er kompentent og dygtig til at fortælle, hvad det er han gør og hvad det betyder for kroppen. Bestemt ikke sidste gang jeg besøger Nordisk Musculupati.`,
    link: 'https://www.facebook.com/samir.karadza/posts/10158741370272048'
  }
]

export const reviews4 = [
  {
    id: 10,
    title: 'Ami Dzihanic',
    body: `Jeg døjede med migræne flere gange om ugen i mange år. Prøvet både fysioterapi og kiropraktik, men uden virkning.
Efter 3 behandlinger hos Benjamin, har jeg ikke haft migræne i 3 uger.
Benjamin er utrolig god til at forklare hvad der sker med kroppen og hvorfor han behandler som han gør. Hvis man døjer med smerter i kroppen, så vil jeg anbefale Benjamin.`,
    link: 'https://www.facebook.com/ami.dzihanic/posts/4006925252678584'
  },
  {
    id: 11,
    title: 'Baris Dalkic',
    body: 'Havde diverse problemer med ryggen  og har både været hos fys og kiropraktor men hjalp ikke noget. Men efter behandling hos Benjamin har det haft en positiv effekt ikke sidste gang jeg besøger ham 👍🏽',
    link: 'https://www.facebook.com/bariskraldalkic/posts/10158537634671684'
  },
  {
    id: 12,
    title: 'Pernille Schmidt',
    body: 'Jeg kan varmt anbefale Nordisk Musculupati. Jeg har i længere tid haft problemer med lænd/bækken, og allerede efter første behandling oplevede jeg bedring. Og så er Benjamins behandling endda behagelig. Det er win-win.',
    link: 'https://www.facebook.com/pernilleschmidt/posts/10223764144841718'
  }
]

export default {
  data: function () {
    return {
      reviews1: reviews1,
      reviews2: reviews2,
      reviews3: reviews3,
      reviews4: reviews4
    }
  },
  head: {
    title: 'Nordisk Musculupati',
    meta: [
      {
        hid: 'index desc',
        name: 'description',
        content: 'Nordisk Musculupati | Manuel behandling af muskel, led-, og nervesmerter'
      },
      // fb
      { property: 'og:title', content: 'Nordisk Musculupati' },
      {
        property: 'og:description',
        content: 'Nordisk Musculupati | Manuel behandling af muskel, led-, og nervesmerter'
      },
      { property: 'og:url', content: 'https://nordiskmusculupati.dk/' }
    ]
  },
  mounted: function () {
    // eslint-disable-next-line no-new
    new Swiper('.swiper-container', {
      loop: true,
      pagination: {
        el: '.swiper-pagination'
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      scrollbar: {
        el: '.swiper-scrollbar'
      }
    })
  }
}
